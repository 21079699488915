import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from './components/Toast/Toast';
import { ENVIRONMENT, HONEYBADGER_KEY } from './config/env';
import './index.css';
import MixpanelClient from './lib/tracking/mixpanel';
import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';

MixpanelClient.init();

ReactDOM.render(
  <>
    <Router>
      <Switch>
        <HoneybadgerErrorBoundary
          honeybadger={Honeybadger.configure({
            apiKey: HONEYBADGER_KEY,
            environment: ENVIRONMENT,
          })}>
          <Route path='/:videoToken' component={App} />
        </HoneybadgerErrorBoundary>
      </Switch>
    </Router>
    <ToastContainer />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
