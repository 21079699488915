import {
  faCalendar,
  faDesktop,
  faPhoneSlash,
  faUserCheck,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import {
  ForwardRefRenderFunction,
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { LocalParticipant } from 'twilio-video';
import { VideoCallStatus } from '../../../../lib/api/Telemedicine/types';
import { UserType } from '../../../../lib/api/Twilio/types';
import MixpanelClient, { TrackingEvent } from '../../../../lib/tracking/mixpanel';
import AVControl from './AVControl';
import ToolBoxButton from './ToolBoxButton';
import './tool-box.css';

export type RefToolbox = {
  setMarkedAsAttended: () => void;
};

type Props = {
  userType: UserType;
  isPresenting: boolean;
  loadingMode: boolean;
  participant: LocalParticipant;
  onToggle: () => void;
  onLeaveRoom: () => void;
  onSetVideoCallStatus: (status: VideoCallStatus) => void;
};

const Toolbox: ForwardRefRenderFunction<RefToolbox, Props> = (
  { userType, isPresenting, loadingMode, participant, onToggle, onLeaveRoom, onSetVideoCallStatus },
  ref: ForwardedRef<RefToolbox>
) => {
  const [hasAttended, setHasAttended] = useState<boolean>(false);
  const [hasReschedule, setHasReschedule] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    setMarkedAsAttended: () => setHasAttended(true),
  }));

  const handleMarkAttended = () => {
    setHasAttended(!hasAttended);
    setHasReschedule(false);

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Attended' },
    });

    onSetVideoCallStatus(VideoCallStatus.Attended);
  };

  const handleReschedule = () => {
    setHasReschedule(!hasReschedule);
    setHasAttended(false);

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Mark as Rescheduled' },
    });

    onSetVideoCallStatus(VideoCallStatus.Rescheduled);
  };

  return (
    <div className='control'>
      <div className='control-inner-container'>
        <AVControl participant={participant} loadingMode={loadingMode} />
      </div>

      <div className='control-inner-container'>
        {userType === UserType.Provider && (
          <ToolBoxButton
            toolTip={isPresenting ? 'Stop Sharing' : 'Share Screen'}
            label={isPresenting ? 'Stop Sharing' : 'Share Screen'}
            onClick={onToggle}
            loadingMode={loadingMode}
            icon={isPresenting ? faVideo : faDesktop}
          />
        )}
        <ToolBoxButton
          toolTip='Leave call'
          label='Leave call'
          onClick={onLeaveRoom}
          loadingMode={loadingMode}
          customStyle='tool-box-button-red'
          icon={faPhoneSlash}
        />
      </div>
      {userType === UserType.Provider ? (
        <div className='control-inner-container'>
          <ToolBoxButton
            toolTip='Mark as Attended'
            label={`Mark as\nAttended`}
            onClick={handleMarkAttended}
            customStyle={hasAttended ? 'toolbox-button-green' : ''}
            loadingMode={loadingMode}
            icon={faUserCheck}
          />
          <ToolBoxButton
            toolTip='Mark as Reschedule'
            label={`Mark as\nReschedule`}
            onClick={handleReschedule}
            loadingMode={loadingMode}
            customStyle={hasReschedule ? 'toolbox-button-green' : ''}
            icon={faCalendar}
          />
        </div>
      ) : (
        <div className='control-inner-container-fixed'> </div>
      )}
    </div>
  );
};

export default forwardRef(Toolbox);
