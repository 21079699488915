export enum AvailableFeatures {
  memberShowTelemedicineModalByClinic = 'member-show-telemedicine-modal-by-clinic',
  ProviderAllowedNewVideoCall = 'provider-allowed-new-video-call',
}

export type FeatureValues = Record<
  string,
  string | number | boolean | string[] | Record<string, string[]>
>;

export type FeatureSource = Record<keyof typeof AvailableFeatures, string>;

export interface Features {
  success: boolean;
  environment: string;
  features: string[];
  values: FeatureValues;
  source: FeatureSource;
  options: object;
  error?: string;
  status?: string;
}
