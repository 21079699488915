import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FC, useEffect, useRef, useState } from 'react';
import { LocalAudioTrack, LocalParticipant, LocalTrack, LocalVideoTrack } from 'twilio-video';
import MixpanelClient, { TrackingEvent } from '../../../../lib/tracking/mixpanel';
import ToolBoxButton from './ToolBoxButton';

type AVControlProps = {
  participant: LocalParticipant;
  loadingMode: boolean;
};

const AVControl: FC<AVControlProps> = ({ participant, loadingMode }) => {
  const [trackAudioOff, setTrackAudioOff] = useState(false);
  const [trackVideoOff, setTrackVideoOff] = useState(false);
  const tracks = useRef<LocalTrack[]>();

  const toggleAudioTrack = () => {
    if (!tracks.current) {
      return;
    }

    const track: LocalAudioTrack | undefined = tracks.current.find((track) => {
      return track.kind === 'audio';
    }) as LocalAudioTrack;

    if (!track) {
      return;
    }

    if (!trackAudioOff) {
      track.disable();
    } else {
      track.enable();
    }

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Toggle Audio', value: trackAudioOff },
    });

    setTrackAudioOff(!trackAudioOff);
  };

  const toggleVideoTrack = () => {
    if (!tracks.current) {
      return;
    }

    const track: LocalVideoTrack | undefined = tracks.current.find((track) => {
      return track.kind === 'video';
    }) as LocalVideoTrack;

    if (!track) {
      return;
    }

    if (!trackVideoOff) {
      track.disable();
    } else {
      track.enable();
    }

    MixpanelClient.trackEvent({
      eventName: TrackingEvent.Click,
      properties: { field: 'Toggle Video', value: trackVideoOff },
    });

    setTrackVideoOff(!trackVideoOff);
  };

  const addTrack = (track: LocalTrack) => {
    if (!tracks.current) {
      return;
    }

    tracks.current = [...tracks.current, track];
  };

  const removeTrack = (track: LocalTrack) => {
    if (!tracks.current) {
      return;
    }

    const cleanTracks = tracks.current.filter((trk) => trk.name !== track.name);
    tracks.current = cleanTracks;
  };

  useEffect(() => {
    const existingPublications = Array.from(participant.tracks.values());
    const existingTracks = existingPublications.map((publication) => publication.track);
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    tracks.current = nonNullTracks;

    participant.on('trackSubscribed', (track) => addTrack(track));
    participant.on('trackUnsubscribed', (track) => removeTrack(track));
  }, []);

  return (
    <>
      <ToolBoxButton
        toolTip={trackAudioOff ? 'Mic' : 'Mute Mic'}
        label={trackAudioOff ? 'Mic' : 'Mute Mic'}
        onClick={toggleAudioTrack}
        loadingMode={loadingMode}
        icon={trackAudioOff ? faMicrophoneSlash : faMicrophone}
      />

      <ToolBoxButton
        toolTip={trackVideoOff ? 'Show Video' : 'Stop Video'}
        label={trackVideoOff ? 'Show Video' : 'Stop Video'}
        onClick={toggleVideoTrack}
        loadingMode={loadingMode}
        icon={trackVideoOff ? faVideoSlash : faVideo}
      />
    </>
  );
};

export default AVControl;
