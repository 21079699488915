import { FEATURE_SERVICE_BASE_URL, FEATURE_SERVICE_ENV } from '../../../config/env';
import { logErrorAndReportToHoneybadger } from '../../errorReporting';
import { api } from '../common/api';
import { AvailableFeatures, FeatureValues, Features } from './types';

export const getFeatures = async (
  values: AvailableFeatures[]
): Promise<FeatureValues | undefined> => {
  const featureFlags = values.join();
  const pathRequest = `${FEATURE_SERVICE_BASE_URL}/features/v1/environments/${FEATURE_SERVICE_ENV}/features/${featureFlags}`;

  try {
    return (await api<Features>(pathRequest)).values;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });

    return undefined;
  }
};

export const isFeatureActiveForValue = (feature: string, value: string | number): boolean => {
  const parsedFeatureFlag = feature === 'true' ? true : feature === 'false' ? false : feature;

  if (typeof parsedFeatureFlag === 'boolean') {
    return parsedFeatureFlag as boolean;
  } else if (typeof parsedFeatureFlag === 'string' && !!value) {
    const values = parsedFeatureFlag
      .replace('[', '')
      .replace(']', '')
      .split(',')
      .map((v) => v.trim()); // To remove spaces
    return values.some((v) => v === `${value}`);
  }
  return false;
};
