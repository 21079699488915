import { Component } from 'react';
import Track from '../Track/Track';

class Participant extends Component {
  constructor(props) {
    super(props);

    const existingPublications = Array.from(this.props.participant.tracks.values());
    const existingTracks = existingPublications.map((publication) => publication.track);
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    this.state = {
      tracks: nonNullTracks,
    };
  }

  componentDidMount() {
    if (!this.props.localParticipant) {
      this.props.participant.on('trackSubscribed', (track) => this.addTrack(track));
      this.props.participant.on('trackUnsubscribed', (track) => this.removeTrack(track));
    } else {
      this.props.participant.on('trackPublished', (track) => this.trackPublished(track));
      this.props.participant.on('trackStopped', (track) => this.trackStopped(track));
    }
  }

  trackStopped(track) {
    // Useful callback if we want to show anything right after the call has finished
    console.log(track);
  }

  trackPublished(track) {
    const existingPublications = Array.from(this.props.participant.tracks.values());
    const existingTracks = existingPublications.map((publication) => publication.track);
    const nonNullTracks = existingTracks.filter((track) => track !== null);

    this.setState({ tracks: nonNullTracks });
  }

  addTrack(track) {
    this.setState({ tracks: [...this.state.tracks, track] });
  }

  removeTrack(track) {
    if (this.state.tracks) {
      this.setState({
        tracks: this.state.tracks.filter((trk) => trk.name !== track.name),
      });
    }
  }

  // TODO: The Track component is not necessary, now just display a div.
  // In the future if is needed remove it or  improve the whole participant component
  render() {
    return (
      <div className='participant' id={this.props.participant.identity}>
        <div className='identity'>{this.props.participant.identity}</div>
        {this.state.tracks.map((track) => (
          <Track key={track} track={track} local={this.props.localParticipant} />
        ))}
      </div>
    );
  }
}

export default Participant;
