import { CORE_V2_BASE_BASE_URL } from '../../../config/env';
import { logErrorAndReportToHoneybadger } from '../../errorReporting';
import { api } from '../common/api';
import { PlaceOfService, PlaceOfServiceRecord, VideoCallStatusParams } from './types';

export const sendTelemedicinePlaceOfService = async (
  placeOfServiceRecord: PlaceOfServiceRecord
): Promise<number | undefined> => {
  const { memberId, scheduleId, place, authToken } = placeOfServiceRecord;

  try {
    const pathRequest = `${CORE_V2_BASE_BASE_URL}/patient/${memberId}/schedulings/${scheduleId}`;

    const data = await api<PlaceOfService>(pathRequest, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ place_of_service: place }),
    });

    return data.id;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });

    return undefined;
  }
};

export const setVideoCallStatusTo = async (statusParams: VideoCallStatusParams): Promise<void> => {
  const { scheduleId, providerId, providerAuthToken, videoCallStatus } = statusParams;

  try {
    await api(`${CORE_V2_BASE_BASE_URL}/providers/${providerId}/schedulings/${scheduleId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${providerAuthToken}`,
      },
      body: JSON.stringify({
        scheduling: {
          attendance_status: videoCallStatus,
        },
      }),
    });
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });
  }
};
