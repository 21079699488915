import { logErrorAndReportToHoneybadger } from './errorReporting';

export enum StorageKeys {
  TelemedicineSchedulingId = 'TELEMEDICINE_SCHEDULING_ID',
}

export const getItem = (key: StorageKeys): string | number | null => {
  const value = localStorage.getItem(key);

  try {
    if (value) {
      return JSON.parse(value);
    }

    return null;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error, isReportable: false });

    return value;
  }
};

export const setItem = (key: StorageKeys, value: string | number): void => {
  return localStorage.setItem(key, JSON.stringify(value));
};
