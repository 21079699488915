import { getMemberPreferences } from '../MembersService/client';

export async function memberCanEnterNewVideoCall(authToken: string) {
  const preferences = await getMemberPreferences(authToken);
  // Find the preference for video call: use_new_video_call
  const preference = preferences?.find((pref) => pref.preference.name === 'use_new_video_call');
  if (!preference) {
    // Default to old video call
    return false;
  }

  return preference.value;
}
