export const CORE_V2_BASE_BASE_URL = process.env.REACT_APP_CORE_V2_BASE_URL!;
export const SCHEDULE_API_BASE_URL = process.env.REACT_APP_SCHEDULE_SERVICE_BASE_URL!;
export const FEATURE_SERVICE_BASE_URL = process.env.REACT_APP_FEATURE_SERVICE_BASE_URL!;
export const MEMBERS_SERVICE_BASE_URL = process.env.REACT_APP_MEMBERS_SERVICE_BASE_URL!;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT!;
export const FEATURE_SERVICE_ENV = process.env.REACT_APP_FEATURE_SERVICE_ENV!;

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN!;
export const HONEYBADGER_KEY = process.env.REACT_APP_HONEYBADGER_KEY!;
