import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

export type ToolBoxButtonProps = {
  toolTip?: string;
  label?: string;
  loadingMode: boolean;
  icon: IconDefinition;
  customStyle?: string;
  onClick: () => void;
};

const ToolBoxButton: FC<ToolBoxButtonProps> = ({
  toolTip = '',
  label = '',
  loadingMode,
  icon,
  customStyle,
  onClick,
}) => {
  return (
    <div
      data-tip={toolTip}
      data-for='toggle-button'
      key='toggle-button'
      className={`tool-box-button`}
      onClick={onClick}>
      <ReactTooltip id='toggle-button' place='top' type='dark' effect='float' />
      <div className={`tool-box-btn-icon-container ${customStyle}`}>
        <FontAwesomeIcon className={`toggle ${loadingMode ? 'disabled' : ''} `} icon={icon} />
      </div>
      <label>{label}</label>
    </div>
  );
};

export default ToolBoxButton;
