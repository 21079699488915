const api = async <T>(url: string, headers?: RequestInit): Promise<T> => {
  return window.fetch(url, headers).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
};

export { api };
