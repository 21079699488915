import { MEMBERS_SERVICE_BASE_URL } from '../../../config/env';
import { logErrorAndReportToHoneybadger } from '../../errorReporting';
import { api } from '../common/api';

type Preference = {
  id: string;
  value: boolean;
  preference: {
    id: number;
    name: string;
    label: string;
  };
};

type MemberPreferencesResponse = {
  data: Preference[];
};

export const getMemberPreferences = async (token: string) => {
  const requestPath = `${MEMBERS_SERVICE_BASE_URL}/v1/members/me/preferences`;

  try {
    const response = await api<MemberPreferencesResponse>(requestPath, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });
  }
  return null;
};
