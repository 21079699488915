import { FC } from 'react';
import { AppIcon, GetReadyIcon, SpinnerIcon, VideoIcon } from '../../../../components/icon/png';
import './get-ready.scss';

type Props = {
  loadingJoin: boolean;
  videoRoomInfoAlreadyObtained: boolean;
  checkTelemedicineBeforeJoiningRoom: () => void;
};

const GetReady: FC<Props> = ({
  loadingJoin,
  videoRoomInfoAlreadyObtained,
  checkTelemedicineBeforeJoiningRoom,
}) => {
  return (
    <div className='home'>
      <nav className='header'>
        <img src={AppIcon} alt='app-icon' />
      </nav>
      <div className='body'>
        <div className='wrapper'>
          <img className='get-ready-icon' src={GetReadyIcon} alt='get-ready-icon' />
          <h2 className='title'>Time to get ready for your appointment</h2>
          <div className='slideshow'>
            <p>
              Be sure to grant permission for microphone and camera access to ensure a smooth
              experience.
            </p>
            <p>Ensure you have a stable internet connection for clear audio and video.</p>
            <p>Find a quiet, well-lit space for the best call quality.</p>
          </div>

          <div className='buttons'>
            <div>
              <button
                id='joinRoom'
                onClick={checkTelemedicineBeforeJoiningRoom}
                disabled={!videoRoomInfoAlreadyObtained || loadingJoin}>
                {loadingJoin ? (
                  <div className='buttonContent'>
                    <div className='iconWrapper'>
                      <img src={SpinnerIcon} height='24' width='24' alt='spinner-icon' />
                    </div>
                    <div>Joining...</div>
                  </div>
                ) : (
                  <div className='buttonContent'>
                    <div className='iconWrapper'>
                      <img src={VideoIcon} height='24' width='24' alt='video-icon' />
                    </div>
                    <div>Join Call</div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetReady;
