import mixpanel, { Dict } from 'mixpanel-browser';
import { ENVIRONMENT, MIXPANEL_TOKEN } from '../../config/env';
import { MemberIdentityDetail, UserType } from '../api/Twilio/types';

export const LEGACY_TRACKING_TAG_VIDEO_CALL_APP = 'twilio-video-app:';
export const LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL = 'twilio-video-app/telemedicine-modal:';

export enum LegacyTrackingEvent {
  TelemedicineClickContinue = 'click continue button',
  TelemedicineClickClose = 'click close button',
  TelemedicineOpen = 'open',
  VideoCallAppOpen = 'open app',
  VideoCallAppClickJoinCall = 'click join call button',
  VideoCallAppProviderSwitchToCamera = 'provider change to camera',
  VideoCallAppProviderSwitchToShareScreen = 'provider change to share screen',
  VideoCallAppLeaveCall = 'leave call',
}

export enum TrackingEvent {
  Click = 'Click',
  Task = 'Task',
}

type TrackingProperties = Dict & {
  field: string;
  source?: string;
  value?: string | boolean;
};

export default class MixpanelClient {
  public static init(): void {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: ENVIRONMENT === 'development',
    });
  }

  public static registerUser({
    userType,
    room,
    identityDetail,
  }: {
    userType?: UserType;
    room?: string;
    identityDetail?: MemberIdentityDetail;
  }): void {
    mixpanel.register({
      user_type: userType,
      room: room,
      clinic_id: identityDetail?.clinic_id || undefined,
      schedule_id: identityDetail?.schedule_id || undefined,
      member_id: identityDetail?.member_id,
    });
  }

  public static trackEvent({
    eventName,
    properties,
  }: {
    eventName: TrackingEvent;
    properties: TrackingProperties;
  }) {
    mixpanel.track(eventName, properties);
  }

  /**
   * @deprecated This is how events in mixpanel were initially tracked
   * (Not respecting the standard as we do in all the other projects that provide tracking)
   */
  public static trackLegacyEventWithTag({
    tag,
    eventName,
    properties,
  }: {
    tag: typeof LEGACY_TRACKING_TAG_VIDEO_CALL_APP | typeof LEGACY_TRACKING_TAG_TELEMEDICINE_MODAL;
    eventName: LegacyTrackingEvent;
    properties?: Dict;
  }): void {
    mixpanel.track(`${tag} ${eventName}`, properties);
  }
}
