import { FC, useEffect, useRef, useState } from 'react';
import { RemoteParticipant, Room } from 'twilio-video';
import {
  ELAPSED_MS_BEFORE_AUTO_MARKING_AS_ATTENDED,
  TOAST_AUTO_CLOSE_DELAY_MS,
} from '../../constants/config';
import { setVideoCallStatusTo } from '../../lib/api/Telemedicine/client';
import { VideoCallStatus } from '../../lib/api/Telemedicine/types';
import { UserType, VideoRoomResponse } from '../../lib/api/Twilio/types';
import { logErrorAndReportToHoneybadger } from '../../lib/errorReporting';
import MixpanelClient, { TrackingEvent } from '../../lib/tracking/mixpanel';
import Toast from '../Toast/Toast';
import Participant from './Components/Participant/Participant';
import Toolbox, { RefToolbox } from './Components/Toolbox/Toolbox';
import WaitingForParticipant from './Components/WaitingForParticipant/WaitingForParticipant';

interface EnaraRoomProps {
  data: VideoRoomResponse;
  room: Room;
  presenting: boolean;
  loadingMode: boolean;
  onLeaveRoom: () => void;
  onToggle: () => void;
}

const EnaraRoom: FC<EnaraRoomProps> = ({
  data,
  room,
  presenting,
  loadingMode,
  onLeaveRoom,
  onToggle,
}) => {
  const [remoteParticipants, setRemoteParticipants] = useState<RemoteParticipant[]>([]);

  const didParticipantAttendedOrReschedule = useRef<boolean>(false);
  const autoMarkAsAttendedTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const toolboxRef = useRef<RefToolbox | null>(null);

  const endCall = () => {
    if (data.user_type === UserType.Provider && !didParticipantAttendedOrReschedule.current) {
      return Toast.warning(
        `Before ending the call make sure to "Mark as Attended" or "Mark as Reschedule"`,
        {
          position: 'bottom-center',
          className: 'tool-box-toast-center',
        }
      );
    }

    cleanUpAndLeaveRoom();
  };

  const cleanUpAndLeaveRoom = async () => {
    try {
      const localTracks = Array.from(room.localParticipant.tracks.values()).map(
        (publication) => publication.track
      );

      room.localParticipant.unpublishTracks(localTracks);
    } catch (error) {
      logErrorAndReportToHoneybadger({ error });
    }

    room.disconnect();

    if (autoMarkAsAttendedTimeoutId.current !== null) {
      clearTimeout(autoMarkAsAttendedTimeoutId.current);
    }

    onLeaveRoom();
  };

  const scheduleAutoMarkAsAttendedForProvider = (participants: RemoteParticipant[]): void => {
    if (data.user_type !== UserType.Provider) {
      return;
    }

    if (!didParticipantAttendedOrReschedule.current && participants.length > 0) {
      autoMarkAsAttendedTimeoutId.current = setTimeout(() => {
        didParticipantAttendedOrReschedule.current = true;

        updateVideoCallStatus(VideoCallStatus.Attended);

        Toast.info('Attendance confirmed', { autoClose: TOAST_AUTO_CLOSE_DELAY_MS });

        MixpanelClient.trackEvent({
          eventName: TrackingEvent.Task,
          properties: { field: 'Auto-mark as Attended' },
        });

        toolboxRef.current?.setMarkedAsAttended();
      }, ELAPSED_MS_BEFORE_AUTO_MARKING_AS_ATTENDED);
    }
  };

  const addParticipant = (participant: RemoteParticipant | RemoteParticipant[]) => {
    const updatedRemoteParticipants = [
      ...remoteParticipants,
      ...(Array.isArray(participant) ? participant : [participant]),
    ];

    setRemoteParticipants(updatedRemoteParticipants);
    scheduleAutoMarkAsAttendedForProvider(updatedRemoteParticipants);
  };

  const removeParticipant = (participant: RemoteParticipant) => {
    const tempRemoteParticipants = remoteParticipants.filter(
      (p) => p.identity !== participant.identity
    );

    setRemoteParticipants(tempRemoteParticipants);
  };

  const handleVideoCallStatusUpdate = (status: VideoCallStatus) => {
    didParticipantAttendedOrReschedule.current = true;

    updateVideoCallStatus(status);

    Toast.info(
      status === VideoCallStatus.Rescheduled ? 'Marked as Reschedule' : 'Marked as Attended',
      {
        position: 'bottom-right',
        className: 'tool-box-toast-right',
      }
    );
  };

  const updateVideoCallStatus = (videoStatus: VideoCallStatus) => {
    const provider = data.provider;

    if (!provider?.schedule_id) {
      return;
    }

    setVideoCallStatusTo({
      scheduleId: provider.schedule_id,
      providerId: provider.id,
      providerAuthToken: provider.auth_token,
      videoCallStatus: videoStatus,
    });
  };

  useEffect(() => {
    room.on('participantConnected', (participant) => addParticipant(participant));
    room.on('participantDisconnected', (participant) => removeParticipant(participant));

    window.addEventListener('beforeunload', cleanUpAndLeaveRoom);

    // Let's register participants that joined the call before the host
    addParticipant(Array.from(room.participants.values()));

    return () => {
      cleanUpAndLeaveRoom();
    };
  }, []);

  return (
    <div className='room'>
      <div className='participants'>
        <div className={`localParticipant ${presenting ? 'noMirror' : ''}`}>
          <Participant
            key={room.localParticipant.identity}
            localParticipant='false'
            participant={room.localParticipant}
          />
        </div>

        <div className='remoteParticipants'>
          {remoteParticipants.map((participant) => (
            <Participant key={participant.identity} participant={participant} />
          ))}

          <WaitingForParticipant
            numberOfRemoteParticipants={remoteParticipants.length}
            roomInfo={data}
            onMarkCallAsNoShow={cleanUpAndLeaveRoom}
          />
        </div>
      </div>

      <Toolbox
        ref={toolboxRef}
        userType={data.user_type}
        isPresenting={presenting}
        loadingMode={loadingMode}
        participant={room.localParticipant}
        onToggle={onToggle}
        onLeaveRoom={endCall}
        onSetVideoCallStatus={handleVideoCallStatusUpdate}
      />
    </div>
  );
};

export default EnaraRoom;
