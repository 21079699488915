import { SCHEDULE_API_BASE_URL } from '../../../config/env';
import { logErrorAndReportToHoneybadger } from '../../errorReporting';
import { api } from '../common/api';
import { VideoRoomResponse } from './types';

export const fetchVideoRoomWithToken = async ({
  token,
  memberId,
}: {
  token: string;
  memberId: string;
}): Promise<VideoRoomResponse | null> => {
  const queryParameters = { token, ...(memberId && { memberId }) };
  const queryParametersAsString = Object.entries(queryParameters).reduce(
    (acc, [key, value]) => `${acc}&${key}=${value}`,
    ''
  );
  try {
    const { data } = await api<{ data: VideoRoomResponse }>(
      `${SCHEDULE_API_BASE_URL}/video-call/v1/token?${queryParametersAsString}`
    );

    return data;
  } catch (error) {
    logErrorAndReportToHoneybadger({ error });

    throw error;
  }
};
